import React, { useEffect, useState } from 'react';
import Livros from './Livros';
import './LojaMaster.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const LojaMaster = (props) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const [items, setItems] = useState([]);
  const [itemCategory, setItemCategory] = useState('');
  const [header_pt, setHeaderPt] = useState([]);
  const [header_en, seHeaderEn] = useState([]);
  const [titlePT, setTitlePT] = useState('');
  const [titleEN, setTitleEN] = useState('');

  const getData = (category) => {
    axios.get(`/store/${category}`).then((res) => {
      setItems(res.data);
      setItemCategory(category);
      setTitlePT(res.data[0].category_pt);
      setTitleEN(res.data[0].category_en);
    });
  };

  const getDataHeader = () => {
    axios
      .get("/store/header/header")
      .then((res) => {
        setHeaderPt(res.data[0].header_pt);
        seHeaderEn(res.data[0].header_en)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const match = props.match;
    let getCategory = match.params.itemCategory;
    getData(getCategory);
    getDataHeader()
  }, [props.match]);

  useEffect(() => {
    const match = props.match;
    const getCategory = match.params.itemCategory;
    document.title = `Associação Guias de Portugal - Loja`;
    if (getCategory !== itemCategory) {
      getData(getCategory);
    }
  });

  useEffect(() => {
    if (i18n.language !== selectedLanguage) {
      setSelectedLanguage(i18n.language);
    }
  }, [i18n.language, selectedLanguage]);

  return (
    <div className="Body">
      <div className="Body-Loja">
        <h2 className="app-second-title tituloLoja">
          {
            selectedLanguage === 'pt' ?
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(titlePT) }}></p>
              :
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(titleEN) }}></p>
          }
        </h2>
        <p className="app-main-text">
          {
            selectedLanguage === 'pt' ?
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(header_pt) }}></p>
              :
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(header_en) }}></p>
          }
        </p>
        <div className="loja">
          {items.map((item) => {
            return item.publish === 1 && <Livros key={item.id} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default LojaMaster;
