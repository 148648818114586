import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ConteudoNoticia.css";
import { useTranslation } from "react-i18next";
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const ConteudoNoticia = (props) => {
  const { i18n } = useTranslation();
  const [contNoticiasData, setContNoticiasData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const { match } = props;
      const { id } = match.params;
      await axios
        .get("/news")
        .then((res) => {
          const noticiasConteudo = res.data.filter(
            (element) => element.id === Number(id)
          );
          setContNoticiasData(noticiasConteudo[0]);
          document.title = `Associação Guias de Portugal - ${noticiasConteudo[0].pt_title}`;
        })
        .catch((err) => {
          document.title = `Erro DB`;
        });
    };
    getData();
  }, [props]);

  useEffect(() => {
    if (i18n.language !== selectedLanguage) {
      setSelectedLanguage(i18n.language);
    }
  }, [i18n.language, selectedLanguage]);

  return (
    <div className="ConteudoNoticia">
      <div className="CardConteudoNoticia">
        <div className="CardConteudoNoticia2">
          <div className="ImgCardConteudoNoticia">
            <img
              className="ImagemConteudo"
              src={contNoticiasData.image}
              alt="notícia"
            />
          </div>
          <div className="TitleCardConteudoNoticia">
            <h3>{contNoticiasData[`${selectedLanguage}_title`]}</h3>
          </div>
          <div className="TextContentNoticias noticias-main-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contNoticiasData[`${selectedLanguage}_content`]) }} />
        </div>
      </div>
    </div>
  );
};

export default ConteudoNoticia;
