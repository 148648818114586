import React from 'react';
import './IntroPage.css';
import whiteLogo from '../../../assets/logo/logo_RGB.png';

const IntroPage = () => {
  document.title = "Backoffice";

  // Obrigar refresh no primeiro load da IntroPage para forçar update do token
  if(window.localStorage) {
    if(!localStorage.getItem('firstLoad')){
      localStorage['firstLoad'] = true;
      window.location.reload();
    }  else {
      localStorage.removeItem('firstLoad');
    }
  }

  return (
    <div className="IntroPage">
      <div className="intro-logo-section">
        <img src={whiteLogo} className="intro-logo" alt="AGP" />
      </div>
    </div>
  );
};

export default IntroPage;
