import React, { useEffect, useState } from "react";
import './AssociacaoMundial.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const AssociacaoMundial2 = () => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [worldAssociationData, setWorldAssociationData] = useState([]);
    
    useEffect(() => {
        document.title = "Associação Guias de Portugal - Associação Mundial"
        window.scrollTo(0, 0)
        const getData = async () => {
            await axios
                .get('/worldAssociation')
                .then((result) => {
                    setWorldAssociationData(result.data[0])
                })
        }
        getData()
    }, [])

    useEffect(() => {
        if (i18n.language !== selectedLanguage) {
          setSelectedLanguage(i18n.language);
        }
    }, [i18n.language, selectedLanguage]);


    return (
        <div>
            <div className="world-girls-banner">
                <img src={worldAssociationData.banner} alt="" />
            </div>
            <div className="AssociacaoMundial">
                <div className="world-association">
                    <img className="world-logo" src={worldAssociationData.logoWAGGGS} alt="World Association Logo" />
                    <p className="app-main-text world-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(worldAssociationData[`section1_${selectedLanguage}`]) }}></p>
                </div>
                <div className="app-blue-banner-title voice-sentence">
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(worldAssociationData[`section2_${selectedLanguage}`]) }}></span>
                </div>
                <div className="world-objectives">
                    <div className="objectives-side">
                        <div className="objectives-title">
                            {worldAssociationData[`section3_column1_title_${selectedLanguage}`]}
                        </div>
                        <ul className="objectives-text">
                            {
                                worldAssociationData[`section3_column1_text1_${selectedLanguage}`] !== '' &&
                                <li>
                                    {worldAssociationData[`section3_column1_text1_${selectedLanguage}`]}
                                </li>
                            }
                            {
                                worldAssociationData[`section3_column1_text2_${selectedLanguage}`] !== '' &&
                                <li>
                                    {worldAssociationData[`section3_column1_text2_${selectedLanguage}`]}
                                </li>
                            }
                            {
                                worldAssociationData[`section3_column1_text3_${selectedLanguage}`] !== '' &&
                                <li>
                                    {worldAssociationData[`section3_column1_text3_${selectedLanguage}`]}
                                </li>
                            }
                            {
                                worldAssociationData[`section3_column1_text4_${selectedLanguage}`] !== '' &&
                                <li>
                                    {worldAssociationData[`section3_column1_text4_${selectedLanguage}`]}
                                </li>
                            }
                            {
                                worldAssociationData[`section3_column1_text5_${selectedLanguage}`] !== '' &&

                                <li>
                                    {worldAssociationData[`section3_column1_text5_${selectedLanguage}`]}
                                </li>

                            }
                        </ul>
                    </div>
                    <div className="objectives-side">
                        <div className="objectives-title">
                            {worldAssociationData[`section3_column2_title_${selectedLanguage}`]}
                        </div>
                        <ul className="objectives-text">
                            {
                                worldAssociationData[`section3_column2_text1_${selectedLanguage}`] !== '' &&
                                <li>{worldAssociationData[`section3_column2_text1_${selectedLanguage}`]}</li>
                            }
                            {
                                worldAssociationData[`section3_column2_text2_${selectedLanguage}`] !== '' &&
                                <li>{worldAssociationData[`section3_column2_text2_${selectedLanguage}`]}</li>
                            }
                            {
                                worldAssociationData[`section3_column2_text3_${selectedLanguage}`] !== '' &&
                                <li>{worldAssociationData[`section3_column2_text3_${selectedLanguage}`]}</li>
                            }
                            {
                                worldAssociationData[`section3_column2_text4_${selectedLanguage}`] !== '' &&
                                <li>{worldAssociationData[`section3_column2_text4_${selectedLanguage}`]}</li>
                            }
                            {
                                worldAssociationData[`section3_column2_text5_${selectedLanguage}`] !== '' &&
                                <li>{worldAssociationData[`section3_column2_text5_${selectedLanguage}`]}</li>
                            }
                        </ul>
                    </div>
                </div>
                <div className="centers">
                    <div className="centers-side">
                        <div className="centers-left">
                            <div className="app-main-title centers-title">
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(worldAssociationData[`section4_title_${selectedLanguage}`]) }}></span>
                            </div>
                            <div className="centers-text">
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(worldAssociationData[`section4_${selectedLanguage}`]) }}></span>
                            </div>
                        </div>
                    </div>
                    <div className="centers-side">
                        <img className="world-centers" src={worldAssociationData.section4_thumbnail} alt="World Thought" />
                    </div>
                </div>
                <div className="thought-section">
                    <div className="left-section">
                        <div className="clover-section">
                            <img className="clover" src={worldAssociationData.section5_column1_thumbnail} alt="Guias de Portugal World Thought" />
                        </div>
                        <div>
                            <div className="penny-title">
                                {worldAssociationData[`section5_column1_title_${selectedLanguage}`]}
                            </div>
                            <div className="penny-text">
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(worldAssociationData[`section5_column1_${selectedLanguage}`]) }}></p>
                            </div>
                        </div>

                    </div>
                    <div className="right-section">
                        <div className="piggy-section">
                            <img className="piggy" src={worldAssociationData.section5_column2_thumbnail} alt="Guias de Portugal World Penny" />
                        </div>
                        <div>
                            <div className="penny-title">
                                <p>{worldAssociationData[`section5_column2_title_${selectedLanguage}`]}</p>
                            </div>
                            <div className="penny-text">
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(worldAssociationData[`section5_column2_${selectedLanguage}`]) }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="associacao-button-section">
                    <Link to="/contactos/formulario">
                        <button type="submit" className="ser-guia-button associacao-button">
                            {t('buttons.queresSerGuia')}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AssociacaoMundial2

