import React, { useEffect, useState } from "react";
import SeccoesAssociacao from "./SeccoesAssociacao";
import "./AssociacaoFront.css";
import "../../App.css"
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window);

const AssociacaoFront2 = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [banner, setBanner] = useState('');
  const [title_pt, setTitlePt] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [text1_pt, setText1Pt] = useState([]);
  const [text1_en, setText1En] = useState([]);
  const [text2_pt, setText2Pt] = useState([]);
  const [text2_en, setText2En] = useState([]);
  const [seccoes, setSeccoes] = useState([]);
  const [title_sections_pt, setTitleSectionsPt] = useState('');
  const [title_sections_en, setTitleSectionsEn] = useState('');

  const fetchAssociationSections = () => {
    axios.get("/association").then((res) => {
      setSeccoes(res.data)
    });
  };

  const fetchAssociationHeader = () => {
    axios.get("/association/associationHeader")
      .then((res) => {
        setBanner(res.data[0].banner);
        setTitlePt(res.data[0].title_pt);
        setTitleEn(res.data[0].title_en);
        setTitleSectionsPt(res.data[0].title_sections_pt);
        setTitleSectionsEn(res.data[0].title_sections_en);
        setText1Pt(res.data[0].text1_pt);
        setText1En(res.data[0].text1_en);
        setText2Pt(res.data[0].text2_pt);
        setText2En(res.data[0].text2_en);
      });
  };

  useEffect(() => {
    document.title = "Associação Guias de Portugal - A Associação";
    window.scrollTo(0, 0);
    fetchAssociationHeader();
    fetchAssociationSections();
  }, []);

  useEffect(() => {
    if (i18n.language !== selectedLanguage) {
      setSelectedLanguage(i18n.language);
    }
  }, [i18n.language, selectedLanguage]);

  return (
    <div className="Body">
      <div className="fotoAssociacao">
        <img src={banner} alt="" />
      </div>

      {selectedLanguage === "pt" ? (
        <div>
          <div className="associacao">
            <div className="app-main-title associacao-title">{title_pt}</div>
            <div className="app-main-text-links" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text1_pt) }} />
          </div>
          <br />
          <div className="app-blue-banner-text guidismo">
            <p>{text2_pt}</p>
          </div>
          <h2 className="app-second-title historiaGuidismoH2">{title_sections_pt}</h2>
        </div>
      ) : (
          <div>
            <div className="associacao">
              <div className="app-main-title associacao-title">{title_en}</div>
              <div className="app-main-text-links" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text1_en) }} />
            </div>
            <br />
            <div className="app-blue-banner-text guidismo">
            <p>{text2_en}</p>
          </div>
            <h2 className="app-second-title historiaGuidismoH2">{title_sections_en}</h2>
          </div>
        )}
      {seccoes.map((seccao, index) => {
        return (
          seccao.publish === 1 && (
            <SeccoesAssociacao
              key={index}
              index={index}
              position={seccao.position}
              section_text_pt={seccao.section_text_pt}
              section_text_en={seccao.section_text_en}
              thumbnail={seccao.section_thumbnail}
            />
          )
        );
      })}
      <div className="associacao-button-section">
        <Link to="/contactos/formulario">
          <button type="submit" className="ser-guia-button associacao-button">
            {t('buttons.queresSerGuia')}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AssociacaoFront2;
