import React, { useEffect, useState } from 'react';
import axios from 'axios';
import JornalCapa from './JornalCapa';
import { useTranslation } from 'react-i18next';
import createDOMPurify from 'dompurify';
import './Jornal.css';

const DOMPurify = createDOMPurify(window);

const Jornal = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [edicoes, setEdicoes] = useState([]);
  const [journalDataHeader, setJournalDataHeader] = useState([]);

  const fetchJournal = () => {
    axios.get('/journal')
      .then((res) => {
        setEdicoes(res.data);
      });
  };

  const getDataHeader = () => {
    axios.get('/journal/headers/headers')
      .then((res) => {
        setJournalDataHeader(res.data[0]);
      });
  }

  useEffect(() => {
    document.title = "Associação Guias de Portugal - Jornal";
    window.scrollTo(0, 0);
    fetchJournal();
    getDataHeader();
  }, []);

  useEffect(() => {
    if (i18n.language !== selectedLanguage) {
      setSelectedLanguage(i18n.language);
    }
  }, [i18n.language, selectedLanguage]);

  return (
    <div className="Jornal">
      <h2 className="app-second-title oTrevo" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('jornal.TituloHomepage')) }} />
      <div>
        <p className="app-main-text textoIntroducao">
          {journalDataHeader[`header1_${selectedLanguage}`]}
        </p>
        <p className="app-main-text  textoIntroducao">
          {journalDataHeader[`header2_${selectedLanguage}`]}
        </p>
      </div>

      <div className="edicoesJornal">
        {edicoes.map((edicao, index) => (
          <>
            {edicao.publish === 1 && (
              <JornalCapa
                key={index}
                titulo_pt={edicao.pt_title}
                titulo_en={edicao.en_title}
                ano={edicao.year}
                edicao={edicao.edition}
                src={edicao.thumbnail}
                pdf_pt={edicao.pdf_link}
                pdf_en={edicao.pdf_lin_en}
                temas1_pt={edicao.pt_intro_text_1}
                temas2_pt={edicao.pt_intro_text_2}
                temas3_pt={edicao.pt_intro_text_3}
                temas4_pt={edicao.pt_intro_text_4}
                temas5_pt={edicao.pt_intro_text_5}
                temas1_en={edicao.en_intro_text_1}
                temas2_en={edicao.en_intro_text_2}
                temas3_en={edicao.en_intro_text_3}
                temas4_en={edicao.en_intro_text_4}
                temas5_en={edicao.en_intro_text_5}
                index={index}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Jornal;
